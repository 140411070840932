import {Alert, Box, Button, Card, Center, Container, Flex, PasswordInput, TextInput} from '@mantine/core';
import {getServerSession} from "next-auth";
import {authOptions} from "../api/auth/[...nextauth]";
import {useForm} from "@mantine/form";
import {signIn} from "next-auth/react";
import {useRouter} from "next/router";
import {IconAlertCircle} from "@tabler/icons-react";
import {useState} from "react";
import Image from "next/image";
import logo from "@/public/logo.png";

export default function LoginView() {
    const router = useRouter()
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const form = useForm({
        initialValues: {
            email: '',
            password: ''
        },
    });

    return (
        <Flex style={{minHeight: "100vh"}} bg="gray.0" direction={"column"} justify={"center"}>
            <Box>  {/* used to inherit parent's width */}
                <Container size={500}>
                    <Card p={"xl"}>
                        <Center>
                            <Image src={logo} alt={"image"} width={300} height={100}
                                   style={{maxWidth: "100%", maxHeight: "100%", objectFit: "contain"}}
                            />
                        </Center>
                        <form onSubmit={form.onSubmit(
                            (values, _event) => {
                                setLoading(true)
                                signIn("credentials", {
                                        username: form.values.email,
                                        password: form.values.password
                                        , redirect: false
                                    }
                                ).then(({ok, error}) => {
                                    if (ok) {
                                        router.push("/");
                                    } else {
                                        let text
                                        if (error === "CredentialsSignin") {
                                            text = 'Λάθος στοιχεία πρόσβασης';
                                        } else {
                                            text = 'Πρόβλημα διακομιστή. Δοκιμάστε ξανά αργότερα';
                                        }
                                        form.setFieldValue('password', '')
                                        setError(text)
                                        setLoading(false)

                                    }
                                })
                            },
                        )}>
                            {error &&
                                <Alert icon={<IconAlertCircle size={16}/>} title="Σφάλμα" color="red" mb="md"
                                       withCloseButton onClose={() => setError(null)}>
                                    {error}
                                </Alert>
                            }
                            <TextInput
                                withAsterisk
                                label="Email"
                                placeholder="email@example.com"
                                value={form.values.email}
                                disabled={loading}
                                onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
                                error={form.errors.email && form.errors.email}
                            />
                            <PasswordInput
                                withAsterisk
                                label="Password"
                                placeholder="Your password"
                                value={form.values.password}
                                disabled={loading}
                                onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
                                error={form.errors.password && form.errors.password}
                                mt="md"
                            />
                            <Button fullWidth mt="xl" type="submit" loading={loading}>
                                Είσοδος
                            </Button>
                        </form>
                    </Card>
                </Container>
            </Box>

        </Flex>
    )
}

export async function getServerSideProps(context) {
    const session = await getServerSession(context.req, context.res, authOptions)
    if (session) {
        return {
            redirect: {destination: "/"},
        };
    }
    return {
        props: {}
    }
}
